import React from 'react';

interface IProps {
  children?: React.ReactNode;
}

const Page = ({ children }: IProps): JSX.Element => {
  return <div className="page">{children}</div>;
};

export default Page;
