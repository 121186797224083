import React from 'react';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { Button, Form, FormGroup } from 'reactstrap';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import intl from 'react-intl-universal';

import Icon from 'components/Icon';
import Radio from 'components/Radio';
import Page from 'components/Page';
import PageHeader from 'components/PageHeader';
import PageFooter from 'components/PageFooter';
import FormBlock from 'modules/forms/components/FormBlock';
import {
  EAppointmentFormStatus,
  EFormDisqualificationType,
  IForm,
} from 'modules/forms/interfaces/form.interfaces';
import { postAppointmentForm, setFormData } from 'modules/forms/actions/form.actions';
import { infectedInsectBitesRoutes } from 'modules/forms/constants/routes.constants';
import { useTypedSelector } from 'hooks/useTypedSelector';
import { getFormResultHTML } from 'modules/forms/helpers/formResult.helper';
import FormResult from './FormResult';

const SecondaryScreening = (): JSX.Element => {
  const history = useHistory();
  const dispatch = useDispatch();
  const appointment = useTypedSelector(state => state.form.appointment);
  const stateForm = useTypedSelector(state => state.form.form);
  const postLoading = useTypedSelector(state => state.form.loading.postForm);
  const methods = useForm<IForm>({
    defaultValues: {
      hasSymptoms: stateForm.hasSymptoms,
    },
    mode: 'onSubmit',
  });
  const { control, watch, handleSubmit } = methods;
  const hasSymptoms = watch('hasSymptoms');

  const onBack = () => {
    history.goBack();
  };

  const onSubmit = (values: IForm) => {
    dispatch(setFormData(values));
    if (values.hasSymptoms === true) {
      dispatch(
        setFormData({
          disqualificationType: EFormDisqualificationType.TO_A_AND_E,
        }),
      );
      dispatch(
        postAppointmentForm({
          form: {
            appointmentUuid: appointment?.uuid,
            status: EAppointmentFormStatus.TO_A_AND_E,
            response: getFormResultHTML({ ...stateForm, ...values }, FormResult),
          },
          route: infectedInsectBitesRoutes.disqualification,
        }),
      );
    } else {
      history.push(infectedInsectBitesRoutes.assessment);
    }
  };

  return (
    <Page>
      <PageHeader step={2} maxSteps={5} />
      <FormProvider {...methods}>
        <Form className="page" onSubmit={handleSubmit(onSubmit)}>
          <div className="page-content">
            <main id="page-main-scrollable" className="page-main">
              <FormBlock
                title="Does the patient have any of the following symptoms?"
                description={
                  <>
                    If the Insect Bites are near the patient&apos;s mouth or throat:
                    <ul>
                      <li>There are concerns of swelling in the mouth or throat. </li>
                      <li>
                        Patient is having difficulty swallowing, tightness in the throat or hoarse
                        voice.
                      </li>
                      <li>Difficulty breathing or breathing very fast.</li>
                      <li>Swelling of the patient&apos;s throat or tongue.</li>
                    </ul>
                    <br />
                    If the Insect Bites are near the patient&apos;s eye(s):
                    <ul>
                      <li>
                        Trouble moving the eyes: It could be difficult to move the eyes in any
                        direction.
                      </li>
                      <li>Warmth: The skin around the eyes might feel warmer than usual.</li>
                      <li>Pain or tenderness: It may hurt or feel sore around the eyes.</li>
                      <li>
                        Redness and swelling: The area around the eyes might look more red and
                        puffy.
                      </li>
                    </ul>
                  </>
                }
              >
                <Controller
                  name="hasSymptoms"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <FormGroup>
                      <Radio
                        checked={value === true}
                        onChange={() => onChange(true)}
                        label="Yes, the patient is experiencing one or more of the above symptoms"
                      />
                      <Radio
                        checked={value === false}
                        onChange={() => onChange(false)}
                        label="No, the patient is not experiencing any of the above symptoms"
                      />
                    </FormGroup>
                  )}
                />
              </FormBlock>
            </main>

            <PageFooter>
              <Button
                color="primary"
                outline
                type="button"
                block
                disabled={postLoading}
                onClick={onBack}
              >
                <Icon iconName="arrow-left" />
                {intl.get('button.back')}
              </Button>
              <Button
                color="primary"
                type="button"
                block
                disabled={postLoading || hasSymptoms === undefined}
                onClick={handleSubmit(onSubmit)}
              >
                {intl.get('button.next')} <Icon iconName="arrow-right" />
              </Button>
            </PageFooter>
          </div>
        </Form>
      </FormProvider>
    </Page>
  );
};

export default SecondaryScreening;
