export enum EChallengeTypes {
  SmsMFA = 'SMS_MFA',
  MFASetup = 'MFA_SETUP',
  EmailVerify = 'EMAIL_VERIFY',
  SmsVerify = 'SMS_VERIFY',
  NewPasswordRequired = 'NEW_PASSWORD_REQUIRED',
  Authenticate = 'AUTHENTICATED',
  Success = 'SUCCESS',
  AGREEMENT_TERMS = 'AGREEMENT_TERMS',
}

export interface IChallengeMetadata {
  mobilePhone?: string;
  recoveryCode?: string;
}

export interface IChallenge {
  challenge?: EChallengeTypes;
  mobilePhone?: string;
  recoveryCode?: string;
  securityCode?: string;
  password?: string;
  confirmNewPassword?: string;
  session: string;
}
