import React, { ChangeEvent } from 'react';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { Button, Form, FormGroup } from 'reactstrap';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import intl from 'react-intl-universal';

import Icon from 'components/Icon';
import Page from 'components/Page';
import PageHeader from 'components/PageHeader';
import Checkbox from 'components/Checkbox';
import PageFooter from 'components/PageFooter';
import FormBlock from 'modules/forms/components/FormBlock';
import {
  EAppointmentFormStatus,
  EFormDisqualificationType,
  IForm,
} from 'modules/forms/interfaces/form.interfaces';
import { postAppointmentForm, setFormData } from 'modules/forms/actions/form.actions';
import { urinaryTractInfectionRoutes } from 'modules/forms/constants/routes.constants';
import { useTypedSelector } from 'hooks/useTypedSelector';
import { getFormResultHTML } from 'modules/forms/helpers/formResult.helper';
import FormResult from './FormResult';

const Assessment = (): JSX.Element => {
  const history = useHistory();
  const dispatch = useDispatch();
  const appointment = useTypedSelector(state => state.form.appointment);
  const stateForm = useTypedSelector(state => state.form.form);
  const methods = useForm<IForm>({
    defaultValues: {
      statements: stateForm.statements,
    },
    mode: 'onSubmit',
  });
  const { control, watch, setValue, handleSubmit } = methods;
  const statements = watch('statements') || [];

  const onBack = () => {
    history.goBack();
  };

  const onSubmit = (values: IForm) => {
    dispatch(setFormData(values));
    if (!values.statements?.includes('6')) {
      dispatch(
        setFormData({
          disqualificationType: EFormDisqualificationType.TO_URGENT_CARE,
        }),
      );
      dispatch(
        postAppointmentForm({
          form: {
            appointmentUuid: appointment?.uuid,
            status: EAppointmentFormStatus.TO_URGENT_CARE,
            response: getFormResultHTML({ ...stateForm, ...values }, FormResult),
          },
          route: urinaryTractInfectionRoutes.disqualification,
        }),
      );
    } else {
      history.push(urinaryTractInfectionRoutes.secondaryAssessment);
    }
  };

  const onChangeValue = (name: string, e: ChangeEvent<HTMLInputElement>) => {
    setValue(
      'statements',
      e.target.checked
        ? [...statements, name].filter(item => item !== '6')
        : statements.filter(item => item !== name),
    );
  };

  return (
    <Page>
      <PageHeader step={3} />
      <FormProvider {...methods}>
        <Form className="page" onSubmit={handleSubmit(onSubmit)}>
          <div className="page-content">
            <main id="page-main-scrollable" className="page-main">
              <FormBlock
                title="Does the patient have any of the following symptoms?"
                description="In addition to symptoms related to a UTI, is the patient experiencing any of the following:"
              >
                <Controller
                  name="statements"
                  control={control}
                  render={() => (
                    <FormGroup>
                      <Checkbox
                        id="1"
                        name="1"
                        checked={statements?.includes('1')}
                        onChange={e => onChangeValue('1', e)}
                        label="Kidney pain/tenderness in the back under the patient's ribs"
                      />
                      <Checkbox
                        id="2"
                        name="2"
                        checked={statements?.includes('2')}
                        onChange={e => onChangeValue('2', e)}
                        label="New or unusual muscle aches and pain or flu like illness"
                      />
                      <Checkbox
                        id="3"
                        name="3"
                        checked={statements?.includes('3')}
                        onChange={e => onChangeValue('3', e)}
                        label="Extremely cold or shivering intensely (similar to when you have the flu)"
                      />
                      <Checkbox
                        id="4"
                        name="4"
                        checked={statements?.includes('4')}
                        onChange={e => onChangeValue('4', e)}
                        label="Temperature high, 37.9°C or above"
                      />
                      <Checkbox
                        id="5"
                        name="5"
                        checked={statements?.includes('5')}
                        onChange={e => onChangeValue('5', e)}
                        label="Nausea/vomiting"
                      />
                      <Checkbox
                        id="6"
                        name="6"
                        checked={statements?.includes('6')}
                        onChange={e => setValue('statements', e.target.checked ? ['6'] : [])}
                        label="None of the Above"
                      />
                    </FormGroup>
                  )}
                />
              </FormBlock>
            </main>

            <PageFooter>
              <Button color="primary" outline type="button" block onClick={onBack}>
                <Icon iconName="arrow-left" />
                {intl.get('button.back')}
              </Button>
              <Button
                color="primary"
                type="button"
                block
                disabled={!statements?.length}
                onClick={handleSubmit(onSubmit)}
              >
                {intl.get('button.next')} <Icon iconName="arrow-right" />
              </Button>
            </PageFooter>
          </div>
        </Form>
      </FormProvider>
    </Page>
  );
};

export default Assessment;
