import React, { ChangeEvent } from 'react';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { Button, Form, FormGroup } from 'reactstrap';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import intl from 'react-intl-universal';

import Icon from 'components/Icon';
import Page from 'components/Page';
import PageHeader from 'components/PageHeader';
import Checkbox from 'components/Checkbox';
import PageFooter from 'components/PageFooter';
import FormBlock from 'modules/forms/components/FormBlock';
import { EAppointmentFormStatus, IForm } from 'modules/forms/interfaces/form.interfaces';
import { setFormData } from 'modules/forms/actions/form.actions';
import { impetigoRoutes } from 'modules/forms/constants/routes.constants';
import { useTypedSelector } from 'hooks/useTypedSelector';

const Assessment = (): JSX.Element => {
  const history = useHistory();
  const dispatch = useDispatch();
  const stateForm = useTypedSelector(state => state.form.form);
  const methods = useForm<IForm>({
    defaultValues: {
      symptoms: stateForm.symptoms,
    },
    mode: 'onSubmit',
  });
  const { control, watch, setValue, handleSubmit } = methods;
  const symptoms = watch('symptoms') || [];

  const onBack = () => {
    history.goBack();
  };

  const onSubmit = (values: IForm) => {
    dispatch(
      setFormData({
        ...values,
        status: values.symptoms?.includes('6') ? EAppointmentFormStatus.NEEDS_REVIEW : undefined,
      }),
    );
    history.push(impetigoRoutes.photoUpload);
  };

  const onChangeValue = (name: string, e: ChangeEvent<HTMLInputElement>) => {
    setValue(
      'symptoms',
      e.target.checked
        ? [...symptoms, name].filter(item => item !== '6')
        : symptoms.filter(item => item !== name),
    );
  };

  return (
    <Page>
      <PageHeader step={3} />
      <FormProvider {...methods}>
        <Form className="page" onSubmit={handleSubmit(onSubmit)}>
          <div className="page-content">
            <main id="page-main-scrollable" className="page-main">
              <FormBlock
                title="Assessment"
                description="From the below statements, please choose the items that apply to the patient's rash:"
              >
                <Controller
                  name="symptoms"
                  control={control}
                  render={() => (
                    <FormGroup>
                      <Checkbox
                        id="1"
                        name="1"
                        checked={symptoms?.includes('1')}
                        onChange={e => onChangeValue('1', e)}
                        label="It usually doesn't hurt but sometimes it might be a little itchy."
                      />
                      <Checkbox
                        id="2"
                        name="2"
                        checked={symptoms?.includes('2')}
                        onChange={e => onChangeValue('2', e)}
                        label="The rash appears to be spreading to other areas of the body."
                      />
                      <Checkbox
                        id="3"
                        name="3"
                        checked={symptoms?.includes('3')}
                        onChange={e => onChangeValue('3', e)}
                        label="Most of the rash is either on the patient's face (around the mouth and nose), arms, and folds (like the armpits)."
                      />
                      <Checkbox
                        id="4"
                        name="4"
                        checked={symptoms?.includes('4')}
                        onChange={e => onChangeValue('4', e)}
                        label="The initial sores are very thin-walled blisters on a red base which break easily."
                      />
                      <Checkbox
                        id="5"
                        name="5"
                        checked={symptoms?.includes('5')}
                        onChange={e => onChangeValue('5', e)}
                        label="As the rash dries it creates golden yellow or yellow-brown crusts."
                      />
                      <Checkbox
                        id="6"
                        name="6"
                        checked={symptoms?.includes('6')}
                        onChange={e => setValue('symptoms', e.target.checked ? ['6'] : [])}
                        label="None of the Above"
                      />
                    </FormGroup>
                  )}
                />
              </FormBlock>
            </main>

            <PageFooter>
              <Button color="primary" outline type="button" block onClick={onBack}>
                <Icon iconName="arrow-left" />
                {intl.get('button.back')}
              </Button>
              <Button
                color="primary"
                type="button"
                block
                disabled={!symptoms?.length}
                onClick={handleSubmit(onSubmit)}
              >
                {intl.get('button.next')} <Icon iconName="arrow-right" />
              </Button>
            </PageFooter>
          </div>
        </Form>
      </FormProvider>
    </Page>
  );
};

export default Assessment;
