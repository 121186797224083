import { AnyAction } from 'redux';

import { signOutSuccess } from 'modules/auth/actions/auth.actions';
import * as ActionTypes from 'modules/forms/actions/form.actions';
import { IAppointment, IForm } from '../interfaces/form.interfaces';

export interface IFormStore {
  appointment: IAppointment | null;
  formUuid: string;
  formTitle: string;
  form: IForm;
  loading: {
    getForm: boolean;
    postForm: boolean;
  };
  errors: {
    getForm: string | null;
  };
}

const initialState: IFormStore = {
  appointment: null,
  formUuid: '',
  formTitle: '',
  form: {
    status: undefined,
    passesGeneralQualification: undefined,
    hasSymptoms: undefined,
    symptoms: [],
    photos: [],
    episodes: undefined,
    statements: [],
    additionalSymptoms: [],
    secondarySymptoms: [],
    hasSecondarySymptoms: undefined,
    hasThirdSymptoms: undefined,
    hasFourthSymptoms: undefined,
  },
  loading: {
    getForm: false,
    postForm: false,
  },
  errors: {
    getForm: null,
  },
};

const formReducer = (state = initialState, action: AnyAction): IFormStore => {
  switch (action.type) {
    case ActionTypes.setFormUuid.type:
      return {
        ...state,
        formUuid: action.payload.uuid,
        formTitle: action.payload.title,
      };
    case ActionTypes.getFormAppointment.type:
      return {
        ...state,
        loading: {
          ...state.loading,
          getForm: true,
        },
      };
    case ActionTypes.getFormAppointmentSuccess.type:
      return {
        ...state,
        appointment: action.payload as IAppointment,
        loading: {
          ...state.loading,
          getForm: false,
        },
      };
    case ActionTypes.getFormAppointmentError.type:
      return {
        ...state,
        loading: {
          ...state.loading,
          getForm: false,
        },
      };
    case ActionTypes.postAppointmentForm.type:
      return {
        ...state,
        loading: {
          ...state.loading,
          postForm: true,
        },
      };
    case ActionTypes.postAppointmentFormSuccess.type:
      return {
        ...state,
        loading: {
          ...state.loading,
          postForm: false,
        },
      };
    case ActionTypes.postAppointmentFormError.type:
      return {
        ...state,
        loading: {
          ...state.loading,
          postForm: false,
        },
      };
    case ActionTypes.setFormData.type:
      return {
        ...state,
        form: {
          ...state.form,
          ...action.payload,
        },
      };
    case signOutSuccess.type:
      return initialState;
    default:
      return state;
  }
};

export default formReducer;
