import React, { memo } from 'react';
import intl from 'react-intl-universal';
import classNames from 'classnames';

import BeWellLogo from 'assets/svg/bewell.svg';
import NHSLogo from 'assets/svg/nhs.png';
import useScrollStatus from 'hooks/useScrollStatus';
import { useTypedSelector } from 'hooks/useTypedSelector';
import { getBrand } from 'helpers/branding.helper';

interface IProps {
  step?: number;
  maxSteps?: number;
  isComplete?: boolean;
}

const PageHeader = ({ step, maxSteps = 4, isComplete }: IProps): JSX.Element => {
  const branding = useTypedSelector(state => state.app.branding);
  const formTitle = useTypedSelector(state => state.form.formTitle);

  const { isScrolledFromTop } = useScrollStatus('page-main-scrollable');

  return (
    <header className={classNames('page-header', { 'page-header--shadow': isScrolledFromTop })}>
      <div className="page-header-top">
        <div className="page-header__logo">
          <img
            src={(branding?.logos?.mobile as string) || BeWellLogo}
            alt={getBrand() || intl.get('common.brandTitle')}
          />
        </div>
        <div className="page-header__service-logo">
          <img src={NHSLogo} alt={intl.get('forms.nhs')} />
        </div>
      </div>

      <div className="page-header-row">
        <h1 className="page-header-row__title">{`${intl.get('forms.patientIntakeForm')}${
          formTitle ? `: ${formTitle}` : ''
        }`}</h1>
        {(step || isComplete) && (
          <div className="stepper">
            <div className="stepper__label">
              {isComplete
                ? `${intl.get('forms.complete')}!`
                : `${intl.get('forms.step')} ${step || 1}/${maxSteps}`}
            </div>
            <div className="stepper__bar">
              <div
                className="stepper__progress"
                style={{ width: isComplete ? '100%' : `${(100 / maxSteps) * (step || 1)}%` }}
              />
            </div>
          </div>
        )}
      </div>
    </header>
  );
};

export default memo(PageHeader);
