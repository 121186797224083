import React from 'react';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { Button } from 'reactstrap';
import intl from 'react-intl-universal';

import Icon from 'components/Icon';
import Page from 'components/Page';
import PageHeader from 'components/PageHeader';
import PageFooter from 'components/PageFooter';
import { closeForm } from 'modules/forms/actions/form.actions';
import FormResultBlock from 'modules/forms/components/FormResultBlock';
import { useTypedSelector } from 'hooks/useTypedSelector';
import { EFormDisqualificationType } from 'modules/forms/interfaces/form.interfaces';

const Disqualification = (): JSX.Element => {
  const dispatch = useDispatch();
  const history = useHistory();
  const stateForm = useTypedSelector(state => state.form.form);

  const onSubmit = () => {
    dispatch(closeForm());
  };

  const onBack = () => {
    history.goBack();
  };

  return (
    <Page>
      <PageHeader isComplete />
      <div className="page-content">
        <main id="page-main-scrollable" className="page-main">
          {stateForm.disqualificationType !== EFormDisqualificationType.TO_A_AND_E &&
            stateForm.disqualificationType !== EFormDisqualificationType.TO_URGENT_CARE && (
              <FormResultBlock title="Thank you for answering our questions!" color="error">
                Based on the information you shared with us
                <br />
                it is likely that you may require a consultation with your GP.
                <br />
                Given your responses, we are unable to treat you in the pharmacy.
                <br />
                <br />
                We will cancel your appointment on your behalf so that you don&apos;t have to.
                <br />
                <br />
                <b>Please contact your GP as soon as possible.</b>
                <br />
                <br />
                We hope you feel better soon.
              </FormResultBlock>
            )}
          {stateForm.disqualificationType === EFormDisqualificationType.TO_A_AND_E && (
            <FormResultBlock title="Thank you for answering our questions!" color="error">
              Based on the information you shared with us
              <br />
              it is likely that you may require urgent care and we are unable to treat you in the
              pharmacy.
              <br />
              <br />
              We will cancel your appointment on your behalf so that you don&apos;t have to.
              <br />
              <br />
              <b>
                Please visit A&E for assistance as soon as possible. In case of emergency call 999.
              </b>
              <br />
              <br />
              We hope you feel better soon.
            </FormResultBlock>
          )}
          {stateForm.disqualificationType === EFormDisqualificationType.TO_URGENT_CARE && (
            <FormResultBlock title="Thank you for answering our questions!" color="error">
              Based on the information you shared with us
              <br />
              it is likely that you may require an urgent consultation with your GP today.
              <br />
              <br />
              We will cancel your appointment on your behalf so that you don&apos;t have to.
              <br />
              <br />
              <b>
                Please contact your GP immediately or your out of hours doctor&apos;s service (NHS
                111).
              </b>
              <br />
              <br />
              We hope you feel better soon.
            </FormResultBlock>
          )}
        </main>

        {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          !!window.ReactNativeWebView && (
            <PageFooter>
              <Button color="primary" outline type="button" block onClick={onBack}>
                <Icon iconName="arrow-left" />
                {intl.get('button.back')}
              </Button>
              <Button color="primary" type="button" block onClick={onSubmit}>
                {intl.get('button.close')}
              </Button>
            </PageFooter>
          )
        }
      </div>
    </Page>
  );
};

export default Disqualification;
