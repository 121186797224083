import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { urinaryTractInfectionRoutes } from 'modules/forms/constants/routes.constants';
import Assessment from './Assessment';
import SecondaryAssessment from './SecondaryAssessment';
import Confirmation from './Confirmation';
import Disqualification from '../Disqualification';
import GenerealQualifier from './GenerealQualifier';
import Prescreening from './Prescreening';
import SecondaryScreening from './SecondaryScreening';

const FormRoutes = (): JSX.Element => {
  return (
    <Switch>
      <Route path={urinaryTractInfectionRoutes.prescreening} exact component={Prescreening} />
      <Route
        path={urinaryTractInfectionRoutes.generalQualifier}
        exact
        component={GenerealQualifier}
      />
      <Route
        path={urinaryTractInfectionRoutes.secondaryScreening}
        exact
        component={SecondaryScreening}
      />
      <Route path={urinaryTractInfectionRoutes.assessment} exact component={Assessment} />
      <Route
        path={urinaryTractInfectionRoutes.secondaryAssessment}
        exact
        component={SecondaryAssessment}
      />
      <Route
        path={urinaryTractInfectionRoutes.disqualification}
        exact
        component={Disqualification}
      />
      <Route path={urinaryTractInfectionRoutes.confirmation} exact component={Confirmation} />
      <Redirect to={urinaryTractInfectionRoutes.prescreening} />
    </Switch>
  );
};

export default FormRoutes;
