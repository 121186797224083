import { TFile } from 'interfaces/common';
import { TGuest } from 'interfaces/user.interfaces';

export interface IAppointmentForm {
  uuid: string;
  response: string;
  status: EAppointmentFormStatus;
  appointmentUuid: string;
  locationUuid?: string;
  customerUuid?: string;
  formUuid?: string;
  guestDto?: TGuest;
  attachments?: TFile[];
}

export enum EAppointmentFormStatus {
  NOT_SUBMITTED = 'NOT_SUBMITTED',
  NEEDS_REVIEW = 'NEEDS_REVIEW',
  COMPLETED = 'COMPLETED',
  TO_GP = 'TO_GP',
  TO_A_AND_E = 'TO_A_AND_E',
  TO_URGENT_CARE = 'TO_URGENT_CARE',
}

export interface IAppointment {
  uuid: string;
  customerUuid: string;
  serviceUuid: string;
  service: IPharmacyService;
  date: string;
  status: EAppointmentsStatus;
  customerNote?: string;
  threadUuid: string;
  cost: number;
  serviceTitle: string;
  updateReason?: string;
  guest?: TGuest;
  walkIn?: boolean;
  attended?: boolean;
  formLink?: string;
  formDto?: IAppointmentForm;
}

export enum EAppointmentsStatus {
  ACTIVE = 'ACTIVE',
  CANCELED = 'CANCELED',
}

export interface IPharmacyService {
  uuid: string;
  title: string;
  displayName?: string;
  description?: string;
  duration: number;
  private?: boolean;
  locationUuid: string;
  appointmentType: EAppointmentType;
  cost: number;
  formUuid?: string;
}

export enum EAppointmentType {
  VIRTUAL = 'VIRTUAL',
  IN_PERSON = 'IN_PERSON',
}

export interface IForm {
  status?: EAppointmentFormStatus;
  passesGeneralQualification?: boolean;
  hasSymptoms?: boolean;
  hasSecondarySymptoms?: boolean;
  hasThirdSymptoms?: boolean;
  hasFourthSymptoms?: boolean;
  symptoms?: string[];
  secondarySymptoms?: string[];
  additionalSymptoms?: string[];
  statements?: string[];
  episodes?: number;
  photos?: File[];
  disqualificationType?: EFormDisqualificationType;
}

export enum EFormDisqualificationType {
  TO_GP = 'TO_GP',
  TO_A_AND_E = 'TO_A_AND_E',
  TO_URGENT_CARE = 'TO_URGENT_CARE',
}
