import React from 'react';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { Button, Form, FormGroup } from 'reactstrap';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import intl from 'react-intl-universal';

import Icon from 'components/Icon';
import Radio from 'components/Radio';
import Page from 'components/Page';
import PageHeader from 'components/PageHeader';
import PageFooter from 'components/PageFooter';
import FormBlock from 'modules/forms/components/FormBlock';
import {
  EAppointmentFormStatus,
  EFormDisqualificationType,
  IForm,
} from 'modules/forms/interfaces/form.interfaces';
import { postAppointmentForm, setFormData } from 'modules/forms/actions/form.actions';
import { acuteOtitisMediaRoutes } from 'modules/forms/constants/routes.constants';
import { useTypedSelector } from 'hooks/useTypedSelector';
import { getFormResultHTML } from 'modules/forms/helpers/formResult.helper';
import FormResult from './FormResult';

const Assessment = (): JSX.Element => {
  const history = useHistory();
  const dispatch = useDispatch();
  const appointment = useTypedSelector(state => state.form.appointment);
  const stateForm = useTypedSelector(state => state.form.form);
  const methods = useForm<IForm>({
    defaultValues: {
      hasSymptoms: stateForm.hasSymptoms,
    },
    mode: 'onSubmit',
  });
  const { control, watch, handleSubmit } = methods;
  const hasSymptoms = watch('hasSymptoms');

  const onBack = () => {
    history.goBack();
  };

  const onSubmit = (values: IForm) => {
    dispatch(setFormData(values));
    if (values.hasSymptoms === true) {
      dispatch(
        setFormData({
          disqualificationType: EFormDisqualificationType.TO_A_AND_E,
        }),
      );
      dispatch(
        postAppointmentForm({
          form: {
            appointmentUuid: appointment?.uuid,
            status: EAppointmentFormStatus.TO_A_AND_E,
            response: getFormResultHTML({ ...stateForm, ...values }, FormResult),
          },
          route: acuteOtitisMediaRoutes.disqualification,
        }),
      );
    } else {
      history.push(acuteOtitisMediaRoutes.secondaryAssessment);
    }
  };

  return (
    <Page>
      <PageHeader step={3} maxSteps={5} />
      <FormProvider {...methods}>
        <Form className="page" onSubmit={handleSubmit(onSubmit)}>
          <div className="page-content">
            <main id="page-main-scrollable" className="page-main">
              <FormBlock
                title="Does the patient have any of the following symptoms?"
                description={
                  <>
                    Is the patient for which you have booked this Ear Infection experiencing any of
                    the following:
                    <ul>
                      <li>A stiff neck</li>
                      <li>Dislike of bright lights</li>
                      <li>Rash on the skin that looks blotchy or discoloured</li>
                      <li>Headache behind or around the eyes</li>
                      <li>Severe headache and nausea from increased pressure in the head</li>
                      <li>Feeling confused or acting differently</li>
                      <li>Muscles feel weak or different</li>
                      <li>
                        Does the patient&apos;s face look or feel different; are they having trouble
                        moving one side of their face
                      </li>
                    </ul>
                  </>
                }
              >
                <Controller
                  name="hasSymptoms"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <FormGroup>
                      <Radio checked={value === true} onChange={() => onChange(true)} label="Yes" />
                      <Radio
                        checked={value === false}
                        onChange={() => onChange(false)}
                        label="No"
                      />
                    </FormGroup>
                  )}
                />
              </FormBlock>
            </main>

            <PageFooter>
              <Button color="primary" outline type="button" block onClick={onBack}>
                <Icon iconName="arrow-left" />
                {intl.get('button.back')}
              </Button>
              <Button
                color="primary"
                type="button"
                block
                disabled={hasSymptoms === undefined}
                onClick={handleSubmit(onSubmit)}
              >
                {intl.get('button.next')} <Icon iconName="arrow-right" />
              </Button>
            </PageFooter>
          </div>
        </Form>
      </FormProvider>
    </Page>
  );
};

export default Assessment;
