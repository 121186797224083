import HttpService from 'services/HttpService';
import { IRequestReturnType } from 'interfaces/common';
import { ISignInValues, ISignInResponse } from 'modules/auth/interfaces/auth.interfaces';
import { IChallenge } from '../interfaces/challenge.interfaces';
import { IExchangeToken } from '../interfaces/token.interfaces';

// Sign In
export function signIn<L = ISignInResponse>(values: ISignInValues): Promise<IRequestReturnType<L>> {
  return HttpService.request({
    method: 'post',
    url: 'authenticate',
    data: values,
    withCredentials: true,
  });
}

export function resetPassword<L = ISignInResponse>(values: {
  username: string;
}): Promise<IRequestReturnType<L>> {
  return HttpService.request({ method: 'post', url: 'forgotPassword', data: values });
}

export function respondToAuthChallenge<L = ISignInResponse>(
  challenge: IChallenge,
): Promise<IRequestReturnType<L>> {
  return HttpService.request({ method: 'post', url: 'respondToAuthChallenge', data: challenge });
}

export function resendAuthChallenge<L = ISignInResponse>(
  challenge: IChallenge,
): Promise<IRequestReturnType<L>> {
  return HttpService.request({ method: 'post', url: 'resendAuthChallenge', data: challenge });
}

export function exchangeToken<L = ISignInResponse>(
  data: IExchangeToken,
): Promise<IRequestReturnType<L>> {
  return HttpService.request({ method: 'post', url: 'exchange', data });
}

export function signOut(): Promise<IRequestReturnType> {
  return HttpService.request({ method: 'post', url: 'signOut' });
}

// Unlock
export function unblock(values: { password: string }): Promise<IRequestReturnType> {
  return HttpService.request({ method: 'post', url: 'unblock', data: values });
}

// Ping
export function ping<L = ISignInResponse>(): Promise<IRequestReturnType<L>> {
  return HttpService.request<L>({ method: 'get', url: 'ping' });
}
