import React from 'react';
import { useDispatch } from 'react-redux';
import { Button } from 'reactstrap';
import intl from 'react-intl-universal';

import Page from 'components/Page';
import PageHeader from 'components/PageHeader';
import PageFooter from 'components/PageFooter';
import { closeForm } from 'modules/forms/actions/form.actions';
import FormResultBlock from 'modules/forms/components/FormResultBlock';
import FormBadge from 'modules/forms/components/FormBadge';

const Confirmation = (): JSX.Element => {
  const dispatch = useDispatch();

  const onSubmit = () => {
    dispatch(closeForm());
  };

  return (
    <Page>
      <PageHeader isComplete />
      <div className="page-content">
        <main id="page-main-scrollable" className="page-main">
          <FormResultBlock title="Thank you for answering our questions!">
            Based on the information you shared with us it is likely that you may have Shingles.
            <br />
            Please meet with our pharmacy team who will review this information with the patient to
            confirm and complete the diagnosis of those symptoms.
            <br />
            <br />
            We look forward to seeing you soon!
          </FormResultBlock>

          <FormBadge color="warning" title="Important:">
            If the patient&apos;s symptoms worsen rapidly or significantly at any time before your
            consultation or the patient&apos;s temperature rises to 37.9°C or above do not wait to
            come and see us, immediately seek more urgent care by contacting 999 or A&E for
            assistance.
          </FormBadge>
        </main>

        {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          !!window.ReactNativeWebView && (
            <PageFooter>
              <Button color="primary" type="button" block onClick={onSubmit}>
                {intl.get('button.close')}
              </Button>
            </PageFooter>
          )
        }
      </div>
    </Page>
  );
};

export default Confirmation;
