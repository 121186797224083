import { AnyAction } from 'redux';
import moment, { MomentZone } from 'moment-timezone';

import { ELanguage, IPosition } from 'interfaces/common';
import * as ActionTypes from 'actions/app.actions';
import * as FormActionTypes from 'modules/forms/actions/form.actions';
import { IBranding } from 'interfaces/branding.interfaces';
import { TLocationShort } from 'interfaces/locations.interfaces';
import { DEFAULT_TIME_ZONE, defaultLocale } from 'constants/app.constants';

export interface IAppStore {
  version: string;
  language: ELanguage;
  location: TLocationShort | undefined;
  locationUnreadCount: number | boolean;
  branding: IBranding | null;
  formToken: string;
  singleLocation: TLocationShort | null;
  position: IPosition | null;
  timeZone: MomentZone | null;
  loading: {
    initialize: boolean;
    getPermissions: boolean;
    branding: boolean;
    getUnreadCount: boolean;
  };
  errors: {
    initialize: unknown;
    getPermissions: unknown;
    branding: unknown;
    getUnreadCount: string | null;
  };
  drawer: boolean;
}

const initialState: IAppStore = {
  version: '0.1.0',
  language: defaultLocale,
  location: undefined,
  locationUnreadCount: false,
  branding: null,
  formToken: '',
  singleLocation: null,
  position: null,
  timeZone: moment.tz.zone(DEFAULT_TIME_ZONE),
  loading: {
    initialize: true,
    getPermissions: true,
    branding: true,
    getUnreadCount: true,
  },
  errors: {
    initialize: null,
    getPermissions: null,
    branding: null,
    getUnreadCount: null,
  },
  drawer: false,
};

const appReducer = (state = initialState, action: AnyAction): IAppStore => {
  switch (action.type) {
    case ActionTypes.initializeStart.type: {
      return { ...state, loading: { ...state.loading, initialize: true } };
    }
    case ActionTypes.initializeStop.type: {
      return { ...state, loading: { ...state.loading, initialize: false } };
    }
    case ActionTypes.changeLanguage.type:
      return {
        ...state,
        language: action.payload,
        loading: { ...state.loading, initialize: true },
      };
    case FormActionTypes.setFormToken.type:
      return {
        ...state,
        formToken: action.payload,
      };
    case ActionTypes.getPermission.type:
      return {
        ...state,
        loading: { ...state.loading, getPermissions: true },
        errors: { ...state.errors, getPermissions: null },
      };
    case ActionTypes.getPermissionSuccess.type:
      return {
        ...state,
        loading: { ...state.loading, getPermissions: false },
        errors: { ...state.errors, getPermissions: null },
      };
    case ActionTypes.getPermissionError.type: {
      return {
        ...state,
        loading: { ...state.loading, getPermissions: false },
        errors: { ...state.errors, getPermissions: action.payload || null },
      };
    }

    case ActionTypes.getDomainBranding.type:
      return {
        ...state,
        loading: { ...state.loading, branding: true },
        errors: { ...state.errors, branding: null },
      };
    case ActionTypes.getDomainBrandingSuccess.type:
      return {
        ...state,
        branding: action.payload,
        loading: { ...state.loading, branding: false },
        errors: { ...state.errors, branding: null },
      };
    case ActionTypes.getDomainBrandingError.type: {
      return {
        ...state,
        loading: { ...state.loading, branding: false },
        errors: { ...state.errors, branding: action.payload || null },
      };
    }
    default:
      return state;
  }
};

export default appReducer;
