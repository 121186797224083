import { createAction } from '@reduxjs/toolkit';

import { ELanguage, IListResponse } from 'interfaces/common';
import { IBranding } from 'interfaces/branding.interfaces';
import { IProfile, TGuest } from 'interfaces/user.interfaces';

// Initialize
export const initializeStart = createAction('INITIALIZE_START');
export const initializeStop = createAction('INITIALIZE_STOP');
export const initializeDependencies = createAction('INITIALIZE_DEPENDENCIES');
// Change language
export const changeLanguage = createAction<ELanguage>('CHANGE_LANGUAGE');
// Permissions
export const getPermission = createAction('GET_PERMISSIONS');
export const getPermissionSuccess = createAction('GET_PERMISSIONS_SUCCESS');
export const getPermissionError = createAction<string>('GET_PERMISSIONS_ERROR');
// Customer
export const getProfiles = createAction('GET_PROFILES');
export const getProfilesSuccess = createAction<IListResponse<IProfile>>('GET_PROFILES_SUCCESS');
export const getProfilesError = createAction<string>('GET_PROFILES_ERROR');
// Guest
export const setGuestProfile = createAction<TGuest | null>('SET_GUEST_PROFILE');
// Branding
export const getDomainBranding = createAction('GET_DOMAIN_BRANDING');
export const getDomainBrandingSuccess = createAction<IBranding>('GET_DOMAIN_BRANDING_SUCCESS');
export const getDomainBrandingError = createAction<string>('GET_DOMAIN_BRANDING_ERROR');
