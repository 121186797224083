import { all, call, put, takeEvery } from 'redux-saga/effects';
import { replace } from 'connected-react-router';

import * as api from 'modules/forms/api/forms.api';
import * as ActionTypes from 'modules/forms/actions/form.actions';
import { IAppointment, IAppointmentForm } from 'modules/forms/interfaces/form.interfaces';
import {
  ActionTypePayload,
  EWebviewMessageType,
  IRequestReturnType,
  ISagaResponse,
} from 'interfaces/common';
import {
  shinglesRoutes,
  impetigoRoutes,
  infectedInsectBitesRoutes,
  acuteSoreThroatRoutes,
  acuteSinusitisRoutes,
  acuteOtitisMediaRoutes,
  urinaryTractInfectionRoutes,
} from 'modules/forms/constants/routes.constants';
import {
  forms,
  FORM_UUID_IMPETIGO,
  FORM_UUID_SHINGLES,
  FORM_UUID_INFECTED_INSECT_BITES,
  FORM_UUID_SORE_THROAT,
  FORM_UUID_SINUS_INFECTION,
  FORM_UUID_EARACHE,
  FORM_UUID_URINARY_TRACT_INFECTION,
} from 'constants/app.constants';
import { serverError } from 'helpers/serverError.helper';
import toastEmit from 'helpers/toast.helpers';
import { ETypeToast } from 'interfaces/toast.interfaces';

function* getFormAppointment({ payload }: ActionTypePayload<string>) {
  try {
    const { success, results }: ISagaResponse<IAppointment> = yield call(
      api.getFormAppointment,
      payload,
    );
    if (success) {
      yield put(ActionTypes.getFormAppointmentSuccess(results));
      if (results?.service?.formUuid) {
        yield put(
          ActionTypes.setFormUuid({
            uuid: results?.service?.formUuid,
            title: forms.find(item => item.uuid === results?.service?.formUuid)?.title || '',
          }),
        );
        switch (results?.service?.formUuid) {
          case FORM_UUID_SHINGLES:
            yield put(replace(shinglesRoutes.prescreening));
            break;
          case FORM_UUID_IMPETIGO:
            yield put(replace(impetigoRoutes.prescreening));
            break;
          case FORM_UUID_INFECTED_INSECT_BITES:
            yield put(replace(infectedInsectBitesRoutes.prescreening));
            break;
          case FORM_UUID_SORE_THROAT:
            yield put(replace(acuteSoreThroatRoutes.prescreening));
            break;
          case FORM_UUID_SINUS_INFECTION:
            yield put(replace(acuteSinusitisRoutes.prescreening));
            break;
          case FORM_UUID_EARACHE:
            yield put(replace(acuteOtitisMediaRoutes.prescreening));
            break;
          case FORM_UUID_URINARY_TRACT_INFECTION:
            yield put(replace(urinaryTractInfectionRoutes.prescreening));
            break;
          default:
            break;
        }
      }
    }
  } catch (error) {
    const { message } = error as IRequestReturnType;
    serverError(error);
    yield put(ActionTypes.getFormAppointmentError(message));
  }
}

function* postAppointmentForm({
  payload: { form, photos, route },
}: ActionTypePayload<{ form: Partial<IAppointmentForm>; photos?: File[]; route?: string }>) {
  try {
    const { success, results } = yield call(api.postAppointmentForm, form, photos);
    if (success) {
      yield put(ActionTypes.postAppointmentFormSuccess(results));
      if (route) {
        yield put(replace(route));
      }
    }
  } catch (error) {
    const { message, codes } = error as IRequestReturnType;
    switch (codes?.[0]) {
      case 'already_exists': {
        toastEmit({
          type: ETypeToast.warn,
          title: 'The form has already been submitted',
        });
        break;
      }
      default:
        serverError(error);
        break;
    }
    yield put(ActionTypes.postAppointmentFormError(message));
  }
}

function* closeForm() {
  try {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (window.ReactNativeWebView) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      yield window.ReactNativeWebView.postMessage(
        JSON.stringify({
          type: EWebviewMessageType.CLOSE,
        }),
      );
    }
  } catch (error) {
    serverError(error);
  }
}

export default function* formSagas(): Generator {
  yield all([
    takeEvery(ActionTypes.closeForm.type, closeForm),
    takeEvery(ActionTypes.getFormAppointment.type, getFormAppointment),
    takeEvery(ActionTypes.postAppointmentForm.type, postAppointmentForm),
  ]);
}
