import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { acuteSoreThroatRoutes } from 'modules/forms/constants/routes.constants';
import Assessment from './Assessment';
import SecondaryAssessment from './SecondaryAssessment';
import ThirdAssessment from './ThirdAssessment';
import FourthAssessment from './FourthAssessment';
import FifthAssessment from './FifthAssessment';
import Confirmation from './Confirmation';
import Disqualification from '../Disqualification';
import GenerealQualifier from './GenerealQualifier';
import Prescreening from './Prescreening';
import SecondaryScreening from './SecondaryScreening';

const FormRoutes = (): JSX.Element => {
  return (
    <Switch>
      <Route path={acuteSoreThroatRoutes.prescreening} exact component={Prescreening} />
      <Route path={acuteSoreThroatRoutes.generalQualifier} exact component={GenerealQualifier} />
      <Route path={acuteSoreThroatRoutes.secondaryScreening} exact component={SecondaryScreening} />
      <Route path={acuteSoreThroatRoutes.assessment} exact component={Assessment} />
      <Route
        path={acuteSoreThroatRoutes.secondaryAssessment}
        exact
        component={SecondaryAssessment}
      />
      <Route path={acuteSoreThroatRoutes.thirdAssessment} exact component={ThirdAssessment} />
      <Route path={acuteSoreThroatRoutes.fourthAssessment} exact component={FourthAssessment} />
      <Route path={acuteSoreThroatRoutes.fifthAssessment} exact component={FifthAssessment} />
      <Route path={acuteSoreThroatRoutes.disqualification} exact component={Disqualification} />
      <Route path={acuteSoreThroatRoutes.confirmation} exact component={Confirmation} />
      <Redirect to={acuteSoreThroatRoutes.prescreening} />
    </Switch>
  );
};

export default FormRoutes;
