import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { acuteOtitisMediaRoutes } from 'modules/forms/constants/routes.constants';
import Assessment from './Assessment';
import SecondaryAssessment from './SecondaryAssessment';
import ThirdAssessment from './ThirdAssessment';
import Confirmation from './Confirmation';
import Disqualification from '../Disqualification';
import GenerealQualifier from './GenerealQualifier';
import Prescreening from './Prescreening';
import SecondaryScreening from './SecondaryScreening';

const FormRoutes = (): JSX.Element => {
  return (
    <Switch>
      <Route path={acuteOtitisMediaRoutes.prescreening} exact component={Prescreening} />
      <Route path={acuteOtitisMediaRoutes.generalQualifier} exact component={GenerealQualifier} />
      <Route
        path={acuteOtitisMediaRoutes.secondaryScreening}
        exact
        component={SecondaryScreening}
      />
      <Route path={acuteOtitisMediaRoutes.assessment} exact component={Assessment} />
      <Route
        path={acuteOtitisMediaRoutes.secondaryAssessment}
        exact
        component={SecondaryAssessment}
      />
      <Route path={acuteOtitisMediaRoutes.thirdAssessment} exact component={ThirdAssessment} />
      <Route path={acuteOtitisMediaRoutes.disqualification} exact component={Disqualification} />
      <Route path={acuteOtitisMediaRoutes.confirmation} exact component={Confirmation} />
      <Redirect to={acuteOtitisMediaRoutes.prescreening} />
    </Switch>
  );
};

export default FormRoutes;
