import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { AnyAction, Store } from 'redux';
import { History } from 'history';
import { ToastContainer } from 'react-toastify';

import Spinner from 'components/Spinner';
import PublicRoutes from 'routes/public.routes';
import useComponentWillMount from 'hooks/useComponentWillMount';
import HttpService from 'services/HttpService';
import * as ActionTypes from 'actions/app.actions';
import { IStore, useTypedSelector } from 'config/reducers';

interface IApp {
  history: History;
  store: Store<IStore, AnyAction>;
}

const App = ({ history, store }: IApp): JSX.Element => {
  const dispatch = useDispatch();
  const isLoading = useTypedSelector(state => state.app.loading.initialize);
  const isFormLoading = useTypedSelector(state => state.form.loading.getForm);

  // add the ability to work with store in HttpService
  useComponentWillMount(() => {
    HttpService.setStore(store);
  });

  useEffect(() => {
    dispatch(ActionTypes.initializeStart());
  }, [dispatch]);

  if (isLoading || isFormLoading) {
    return <Spinner fullScreen />;
  }

  return (
    <>
      <PublicRoutes history={history} />
      <ToastContainer
        position="top-center"
        autoClose={5000}
        newestOnTop
        closeOnClick
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};

export default App;
