import HttpService from 'services/HttpService';
import { IRequestReturnType } from 'interfaces/common';
import { IAppointment, IAppointmentForm } from 'modules/forms/interfaces/form.interfaces';

export function getFormAppointment<L = IAppointment>(uuid: string): Promise<IRequestReturnType<L>> {
  return HttpService.request({ method: 'get', url: `/appointments/${uuid}/widget` });
}

export function postAppointmentForm<L = IAppointmentForm>(
  data: Partial<IAppointmentForm>,
  photos?: File[],
): Promise<IRequestReturnType<L>> {
  const formData = new FormData();
  const dto = new Blob([JSON.stringify(data)], {
    type: 'application/json',
  });
  formData.append('dto', dto);
  if (photos?.length) {
    photos.forEach(file => {
      formData.append('attachments', file);
    });
  }
  return HttpService.request({ method: 'post', url: '/appointmentForms', data: formData });
}
