import React from 'react';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { Button, Form, FormGroup } from 'reactstrap';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import intl from 'react-intl-universal';

import Icon from 'components/Icon';
import Radio from 'components/Radio';
import Page from 'components/Page';
import PageHeader from 'components/PageHeader';
import PageFooter from 'components/PageFooter';
import FormBlock from 'modules/forms/components/FormBlock';
import { EAppointmentFormStatus, IForm } from 'modules/forms/interfaces/form.interfaces';
import { postAppointmentForm, setFormData } from 'modules/forms/actions/form.actions';
import { urinaryTractInfectionRoutes } from 'modules/forms/constants/routes.constants';
import { useTypedSelector } from 'hooks/useTypedSelector';
import { getFormResultHTML } from 'modules/forms/helpers/formResult.helper';
import FormResult from './FormResult';

const SecondaryScreening = (): JSX.Element => {
  const history = useHistory();
  const dispatch = useDispatch();
  const appointment = useTypedSelector(state => state.form.appointment);
  const stateForm = useTypedSelector(state => state.form.form);
  const postLoading = useTypedSelector(state => state.form.loading.postForm);
  const methods = useForm<IForm>({
    defaultValues: {
      episodes: stateForm.episodes,
    },
    mode: 'onSubmit',
  });
  const { control, watch, handleSubmit } = methods;
  const episodes = watch('episodes');

  const onBack = () => {
    history.goBack();
  };

  const onSubmit = (values: IForm) => {
    dispatch(setFormData(values));
    if (values.episodes === 2 || values.episodes === 3) {
      dispatch(
        postAppointmentForm({
          form: {
            appointmentUuid: appointment?.uuid,
            status: EAppointmentFormStatus.TO_GP,
            response: getFormResultHTML({ ...stateForm, ...values }, FormResult),
          },
          route: urinaryTractInfectionRoutes.disqualification,
        }),
      );
    } else {
      history.push(urinaryTractInfectionRoutes.assessment);
    }
  };

  return (
    <Page>
      <PageHeader step={2} />
      <FormProvider {...methods}>
        <Form className="page" onSubmit={handleSubmit(onSubmit)}>
          <div className="page-content">
            <main id="page-main-scrollable" className="page-main">
              <FormBlock
                title="Assessment"
                description="Has the patient experienced a Urinary Tract Infection (UTI) as recently as:"
              >
                <Controller
                  name="episodes"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <FormGroup>
                      <Radio
                        checked={value === 0}
                        onChange={() => onChange(0)}
                        label="0 UTI in the past 6 months"
                      />
                      <Radio
                        checked={value === 1}
                        onChange={() => onChange(1)}
                        label="1 UTI in the past 6 months"
                      />
                      <Radio
                        checked={value === 2}
                        onChange={() => onChange(2)}
                        label="2 UTIs in the past 6 months"
                      />
                      <Radio
                        checked={value === 3}
                        onChange={() => onChange(3)}
                        label="3 UTIs in the past 12 months"
                      />
                    </FormGroup>
                  )}
                />
              </FormBlock>
            </main>

            <PageFooter>
              <Button
                color="primary"
                outline
                type="button"
                block
                disabled={postLoading}
                onClick={onBack}
              >
                <Icon iconName="arrow-left" />
                {intl.get('button.back')}
              </Button>
              <Button
                color="primary"
                type="button"
                block
                disabled={postLoading || episodes === undefined}
                onClick={handleSubmit(onSubmit)}
              >
                {intl.get('button.next')} <Icon iconName="arrow-right" />
              </Button>
            </PageFooter>
          </div>
        </Form>
      </FormProvider>
    </Page>
  );
};

export default SecondaryScreening;
