import React from 'react';
import classNames from 'classnames';

import useScrollStatus from 'hooks/useScrollStatus';

interface IProps {
  children?: React.ReactNode;
}

const PageFooter = ({ children }: IProps): JSX.Element => {
  const { isScrolledFromBottom } = useScrollStatus('page-main-scrollable');

  return (
    <footer className={classNames('page-footer', { 'page-footer--shadow': isScrolledFromBottom })}>
      {children}
    </footer>
  );
};

export default PageFooter;
