import { all, call, put, select, take, takeEvery } from 'redux-saga/effects';
import intl from 'react-intl-universal';

import * as api from 'api/app.api';
import * as ActionTypes from 'actions/app.actions';
import * as AuthActionTypes from 'modules/auth/actions/auth.actions';
import * as FormActionTypes from 'modules/forms/actions/form.actions';
import { ISignInResponse } from 'modules/auth/interfaces/auth.interfaces';
import { EExchangeTokenType } from 'modules/auth/interfaces/token.interfaces';
import { ActionTypePayload, ELanguage, IRequestReturnType, ISagaResponse } from 'interfaces/common';
import { IStore } from 'config/reducers';
import LocalStorage from 'services/LocalStorage';
import { serverError } from 'helpers/serverError.helper';
import { getBrand, setTheme } from 'helpers/branding.helper';
import { IBranding } from 'interfaces/branding.interfaces';
import { locales } from 'i18n';

function* getDomainBranding() {
  try {
    const { results, success }: ISagaResponse<IBranding> = yield call(
      api.getDomainBranding,
      getBrand(),
    );
    if (success) {
      setTheme(results);
      yield put(ActionTypes.getDomainBrandingSuccess(results));
    }
  } catch (error) {
    const { message } = error as IRequestReturnType;
    yield put(ActionTypes.getDomainBrandingError(message));
  }
}

function* initializeDependencies() {
  try {
    const formToken = LocalStorage.getItem('formToken');
    if (formToken) {
      yield put(FormActionTypes.setFormToken(formToken));
      yield put(FormActionTypes.getFormAppointment(formToken));
    }
  } catch (error) {
    serverError(error);
  }
}

function* initialize() {
  let hasRefreshToken;
  try {
    const {
      app: { language: currentLocale },
    }: IStore = yield select();
    yield call(() => intl.init({ currentLocale, locales }));

    hasRefreshToken = LocalStorage.getItem('hasRefreshToken');
    const subDomain = getBrand();
    if (subDomain) {
      yield getDomainBranding();
    }

    const queryParams = new URLSearchParams(window.location.search);
    const queryToken = queryParams?.get('token');
    if (queryToken) {
      yield put(FormActionTypes.setFormToken(queryToken));
      LocalStorage.setItem('formToken', queryToken);
      yield put(
        AuthActionTypes.exchangeToken({ token: queryToken, type: EExchangeTokenType.APPOINTMENT }),
      );
      yield take([
        AuthActionTypes.exchangeTokenSuccess.type,
        AuthActionTypes.exchangeTokenError.type,
      ]);
    } else if (hasRefreshToken && JSON.parse(hasRefreshToken)) {
      const {
        success,
        results: { tokens },
      }: ISagaResponse<ISignInResponse> = yield call(api.initialize);
      if (success && tokens?.length) {
        yield put(AuthActionTypes.signInSuccess(tokens));
        yield initializeDependencies();
      }
    }
  } catch (error) {
    serverError(error);
    if (hasRefreshToken && JSON.parse(hasRefreshToken)) {
      yield put(AuthActionTypes.signOut());
    }
  } finally {
    yield put(ActionTypes.initializeStop());
  }
}

function* changeLanguage({ payload }: ActionTypePayload<ELanguage>) {
  yield call(() => intl.init({ currentLocale: payload, locales }));
  yield put(ActionTypes.initializeStop());
}

export default function* appSagas(): Generator {
  yield all([
    takeEvery(ActionTypes.initializeStart.type, initialize),
    takeEvery(ActionTypes.changeLanguage.type, changeLanguage),
    takeEvery(ActionTypes.initializeDependencies.type, initializeDependencies),
    takeEvery(ActionTypes.getDomainBranding.type, getDomainBranding),
  ]);
}
