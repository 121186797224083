import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { acuteSinusitisRoutes } from 'modules/forms/constants/routes.constants';
import Assessment from './Assessment';
import SecondaryAssessment from './SecondaryAssessment';
import ThirdAssessment from './ThirdAssessment';
import FourthAssessment from './FourthAssessment';
import FifthAssessment from './FifthAssessment';
import AdditionalAssessment from './AdditionalAssessment';
import Confirmation from './Confirmation';
import Disqualification from '../Disqualification';
import GenerealQualifier from './GenerealQualifier';
import Prescreening from './Prescreening';

const FormRoutes = (): JSX.Element => {
  return (
    <Switch>
      <Route path={acuteSinusitisRoutes.prescreening} exact component={Prescreening} />
      <Route path={acuteSinusitisRoutes.generalQualifier} exact component={GenerealQualifier} />
      <Route path={acuteSinusitisRoutes.assessment} exact component={Assessment} />
      <Route
        path={acuteSinusitisRoutes.secondaryAssessment}
        exact
        component={SecondaryAssessment}
      />
      <Route path={acuteSinusitisRoutes.thirdAssessment} exact component={ThirdAssessment} />
      <Route path={acuteSinusitisRoutes.fourthAssessment} exact component={FourthAssessment} />
      <Route path={acuteSinusitisRoutes.fifthAssessment} exact component={FifthAssessment} />
      <Route
        path={acuteSinusitisRoutes.additionalAssessment}
        exact
        component={AdditionalAssessment}
      />
      <Route path={acuteSinusitisRoutes.disqualification} exact component={Disqualification} />
      <Route path={acuteSinusitisRoutes.confirmation} exact component={Confirmation} />
      <Redirect to={acuteSinusitisRoutes.prescreening} />
    </Switch>
  );
};

export default FormRoutes;
