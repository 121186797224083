import { useState, useEffect } from 'react';

const useScrollStatus = (
  elementId: string,
): {
  isScrolledFromTop: boolean;
  isScrolledFromBottom: boolean;
} => {
  const [isScrolledFromTop, setScrolledFromTop] = useState(false);
  const [isScrolledFromBottom, setNotFullyScrolledToBottom] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const element = document.getElementById(elementId);
      if (!element) return;

      const { scrollTop, scrollHeight, clientHeight } = element;

      const scrolledFromTop = scrollTop > 0;
      const scrolledFromBottom = Math.abs(scrollHeight - clientHeight - scrollTop) > 3;

      setScrolledFromTop(scrolledFromTop);
      setNotFullyScrolledToBottom(scrolledFromBottom);
    };

    handleScroll();

    const element = document.getElementById(elementId);
    if (element) {
      element.addEventListener('scroll', handleScroll);
      return () => {
        element.removeEventListener('scroll', handleScroll);
      };
    }
  }, [elementId]);

  return { isScrolledFromTop, isScrolledFromBottom };
};

export default useScrollStatus;
