import { createAction } from '@reduxjs/toolkit';

import {
  ISignIn,
  ISignInResponse,
  IResetPassword,
  IResendCode,
} from '../interfaces/auth.interfaces';
import { IChallenge } from '../interfaces/challenge.interfaces';
import { IExchangeToken, IToken } from '../interfaces/token.interfaces';

// Sign In
export const signIn = createAction<ISignIn>('SIGN_IN_REQUEST');
export const signInSuccess = createAction<IToken[]>('SIGN_IN_SUCCESS');
export const signInError = createAction<string>('SIGN_IN_ERROR');

// Refresh
export const refreshTokenSuccess = createAction<ISignInResponse>('REFRESH_TOKEN_SUCCESS');

// Exchange token
export const exchangeToken = createAction<IExchangeToken>('EXCHANGE_TOKEN_REQUEST');
export const exchangeTokenSuccess = createAction<ISignInResponse>('EXCHANGE_TOKEN_SUCCESS');
export const exchangeTokenError = createAction<string>('EXCHANGE_TOKEN_ERROR');

// Challenge
export const challenge = createAction<{
  values: IChallenge;
  setError: (message: string) => void;
  isMfaSetup?: boolean;
}>('CHALLENGE_REQUEST');
export const challengeSuccess = createAction('CHALLENGE_SUCCESS');
export const challengeError = createAction<string>('CHALLENGE_ERROR');

// Reset Password
export const resetPassword = createAction<IResetPassword>('RESET_PASSWORD_REQUEST');
export const resetPasswordSuccess = createAction<ISignInResponse>('RESET_PASSWORD_SUCCESS');
export const resetPasswordError = createAction<string>('RESET_PASSWORD_ERROR');

// Unlock App
export const unblockApp = createAction<{
  values: { password: string };
  setError: (message: string) => void;
}>('UNLOCK_APP_REQUEST');
export const unblockAppSuccess = createAction('UNLOCK_APP_SUCCESS');
export const unblockAppError = createAction<string>('UNLOCK_APP_ERROR');

// Save Authenticate info
export const changeAuthenticated = createAction<boolean>('CHANGE_AUTHENTICATED');

// Session Timeout Block
export const changeSessionTimeoutBlock = createAction<boolean>('CHANGE_SESSION_TIMEOUT_BLOCK');

// Ping activity in pharmacy
export const pingActivity = createAction<{ successFunc: () => void; errorFunc: () => void }>(
  'PING_ACTIVITY',
);

// Sign Out
export const signOut = createAction<string | undefined>('SIGN_OUT');
export const signOutSuccess = createAction('SIGN_OUT_SUCCESS');
export const signOutError = createAction('SIGN_OUT_ERROR');

// Resend Code
export const resendCode = createAction<IResendCode>('RESEND_CODE_REQUEST');
export const resendCodeSuccess = createAction('RESEND_CODE_SUCCESS');
export const resendCodeError = createAction<string>('RESEND_CODE_ERROR');
