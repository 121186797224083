import { combineReducers, Reducer } from 'redux';
import { useSelector, TypedUseSelectorHook } from 'react-redux';
import { connectRouter } from 'connected-react-router';
import { History } from 'history';

import appReducer, { IAppStore } from 'reducers/app.reducers';
// Modules
import authReducer, { IAuthStore } from 'modules/auth/reducers/auth.reducer';
import formReducer, { IFormStore } from 'modules/forms/reducers/form.reducer';

export interface IStore {
  app: IAppStore;
  auth: IAuthStore;
  form: IFormStore;
  router: History;
}

export const useTypedSelector: TypedUseSelectorHook<IStore> = useSelector;

export const rootReducer = (history: History): Reducer =>
  combineReducers({
    app: appReducer,
    auth: authReducer,
    form: formReducer,
    router: connectRouter(history),
  });

export type RootState = ReturnType<typeof rootReducer>;
