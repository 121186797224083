import React, { ChangeEvent } from 'react';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { Button, Form, FormGroup } from 'reactstrap';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import intl from 'react-intl-universal';

import Icon from 'components/Icon';
import Checkbox from 'components/Checkbox';
import Page from 'components/Page';
import PageHeader from 'components/PageHeader';
import PageFooter from 'components/PageFooter';
import FormBlock from 'modules/forms/components/FormBlock';
import { EAppointmentFormStatus, IForm } from 'modules/forms/interfaces/form.interfaces';
import { postAppointmentForm, setFormData } from 'modules/forms/actions/form.actions';
import { acuteSinusitisRoutes } from 'modules/forms/constants/routes.constants';
import { useTypedSelector } from 'hooks/useTypedSelector';
import { getFormResultHTML } from 'modules/forms/helpers/formResult.helper';
import FormResult from './FormResult';

const FifthAssessment = (): JSX.Element => {
  const history = useHistory();
  const dispatch = useDispatch();
  const appointment = useTypedSelector(state => state.form.appointment);
  const stateForm = useTypedSelector(state => state.form.form);
  const methods = useForm<IForm>({
    defaultValues: {
      symptoms: stateForm.symptoms,
    },
    mode: 'onSubmit',
  });
  const { control, watch, setValue, handleSubmit } = methods;
  const symptoms = watch('symptoms') || [];

  const onBack = () => {
    history.goBack();
  };

  const onSubmit = (values: IForm) => {
    dispatch(setFormData(values));
    if (values.symptoms?.includes('1')) {
      dispatch(
        postAppointmentForm({
          form: {
            appointmentUuid: appointment?.uuid,
            status: EAppointmentFormStatus.TO_GP,
            response: getFormResultHTML({ ...stateForm, ...values }, FormResult),
          },
          route: acuteSinusitisRoutes.disqualification,
        }),
      );
    } else {
      dispatch(
        setFormData({
          status:
            values?.symptoms?.includes('2') || values?.symptoms?.includes('3')
              ? EAppointmentFormStatus.NEEDS_REVIEW
              : undefined,
        }),
      );
      history.push(acuteSinusitisRoutes.additionalAssessment);
    }
  };

  const onChangeValue = (name: string, e: ChangeEvent<HTMLInputElement>) => {
    setValue(
      'symptoms',
      e.target.checked
        ? [...symptoms, name].filter(item => item !== '4')
        : symptoms.filter(item => item !== name),
    );
  };

  return (
    <Page>
      <PageHeader step={6} maxSteps={7} />
      <FormProvider {...methods}>
        <Form className="page" onSubmit={handleSubmit(onSubmit)}>
          <div className="page-content">
            <main id="page-main-scrollable" className="page-main">
              <FormBlock
                title="Assessment"
                description="Is the patient experiencing any of the following?"
              >
                <Controller
                  name="symptoms"
                  control={control}
                  render={() => (
                    <FormGroup>
                      <Checkbox
                        id="1"
                        name="1"
                        checked={symptoms?.includes('1')}
                        onChange={e => onChangeValue('1', e)}
                        label="Stiff Neck: Finding it hard to move their neck"
                      />
                      <Checkbox
                        id="2"
                        name="2"
                        checked={symptoms?.includes('2')}
                        onChange={e => onChangeValue('2', e)}
                        label="High Fever: Feeling very hot with a temperature"
                      />
                      <Checkbox
                        id="3"
                        name="3"
                        checked={symptoms?.includes('3')}
                        onChange={e => onChangeValue('3', e)}
                        label="Severe Headache: A very bad headache, especially at the front of the head"
                      />
                      <Checkbox
                        id="4"
                        name="4"
                        checked={symptoms?.includes('4')}
                        onChange={e => setValue('symptoms', e.target.checked ? ['4'] : [])}
                        label="None of the Above"
                      />
                    </FormGroup>
                  )}
                />
              </FormBlock>
            </main>

            <PageFooter>
              <Button color="primary" outline type="button" block onClick={onBack}>
                <Icon iconName="arrow-left" />
                {intl.get('button.back')}
              </Button>
              <Button
                color="primary"
                type="button"
                block
                disabled={!symptoms?.length}
                onClick={handleSubmit(onSubmit)}
              >
                {intl.get('button.next')} <Icon iconName="arrow-right" />
              </Button>
            </PageFooter>
          </div>
        </Form>
      </FormProvider>
    </Page>
  );
};

export default FifthAssessment;
