import { createAction } from '@reduxjs/toolkit';

import { IAppointment, IAppointmentForm, IForm } from 'modules/forms/interfaces/form.interfaces';

// Form Token
export const setFormToken = createAction<string>('SET_FORM_TOKEN');

export const getFormAppointment = createAction<string>('GET_FORM_APPOINTMENT');
export const getFormAppointmentSuccess = createAction<IAppointment>('GET_FORM_APPOINTMENT_SUCCESS');
export const getFormAppointmentError = createAction<string>('GET_FORM_APPOINTMENT_ERROR');

export const postAppointmentForm = createAction<{
  form: Partial<IAppointmentForm>;
  photos?: File[];
  route?: string;
}>('POST_APPOINTMENT_FORM');
export const postAppointmentFormSuccess = createAction<IAppointmentForm>(
  'POST_APPOINTMENT_FORM_SUCCESS',
);
export const postAppointmentFormError = createAction<string>('POST_APPOINTMENT_FORM_ERROR');

export const setFormData = createAction<IForm>('SET_FORM_DATA');
export const setFormUuid = createAction<{ uuid: string; title: string }>('SET_FORM_UUID');

// Close Form
export const closeForm = createAction('CLOSE_FORM');
